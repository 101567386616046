import LandingPage from './routes/LandingPage';

import './App.scss';

function App() {
  return (
    <div className="App">
      <LandingPage />

    </div>
  );
}

export default App;
